export type CharacterType = {
  //Editable Properties
  name?: string; // The character's name.
  summary?: string; // A brief overview of the character.
  description?: string; // A brief description of the character.
  role?: string; // The character's role (e.g., protagonist, antagonist, supporting).
  backstory?: string; // The character's history or backstory.
  motivation?: string; // The character's primary motivation or goal.
  goal?: string; // The character's specific goal in the story.
  conflict?: string; // The conflict or obstacle the character faces.
  arc?: string; // The character's development arc over the course of the story.
  traits?: string; // An array of traits that describe the character.
  notes?: string; // Any additional notes about the character.

  //Read-Only Properties
  order?: number; // The order of the character in the sequence of characters.
  wordCount?: number; // The total number of words in the character's description.
  createdSeconds?: number; // The timestamp for when the character was created.
  updatedSeconds?: number; // The timestamp for when the character was last updated.
  locked?: boolean; // Whether the story is locked for editing.

  //ID Properties
  id?: string; // The unique identifier for the character.
  storyId?: string; // The unique identifier of the story the character belongs to.
  chapterIds?: string[]; // The unique identifiers of chapters in which the character appears.
};

export const defaultCharacter: CharacterType = {
  name: "", // The character's name.
  summary: "", // A brief overview of the character.
  description: "", // A brief description of the character.
  role: "", // The character's role (e.g., protagonist, antagonist, supporting).
  backstory: "", // The character's history or backstory.
  motivation: "", // The character's primary motivation or goal.
  goal: "", // The character's specific goal in the story.
  conflict: "", // The conflict or obstacle the character faces.
  arc: "", // The character's development arc over the course of the story.
  traits: "", // Traits that describe the character.
  notes: "", // Any additional notes about the character.

  order: 0, // The order of the character in the sequence of characters.
  wordCount: 0, // The total number of words in the character's description.
  createdSeconds: 0, // The timestamp for when the character was created.
  updatedSeconds: 0, // The timestamp for when the character was last updated.
  locked: false, // Whether the story is locked for editing.
};
