import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useSelectStory } from "../hooks/useSelectStory";
import CollectionList from "../components/CollectionList";
import { ApiMessageType } from "../typings/ApiMessageType";
import { processChat } from "../utils/processChat";
import { CharacterType } from "../typings/CharacterType";
import useCollection from "../hooks/useCollection";

export default function Characters() {
  const state = useSelector(authState);
  const { storyId } = useParams<{ storyId?: string }>();
  const [story] = useSelectStory(storyId);

  console.log("STORY", story);
  console.log("STORY ID", storyId);

  if (!state.uid) return <div>No user</div>;
  if (!storyId) return <div>No story</div>;
  if (!story) return <div>Loading</div>;

  const generateMultipleItems = async () => {
    const systemPrompt = `Generate a list of characters using the Larry Brooks "Story Engineering" methodology for the following story:\n Titled ${story.name}\nSynopsis:\n${story.synopsis}.`;

    const userPrompt = `Generate a list of characters including name and a 200 word summary. Return the character list in a valid JSON format parsable with JSON.parse as follows: [{"name":"character 1 name", "summary":"character 1 summary", "order": 1}, {"name":"character 2 name", "summary":"character 2 summary", "order": 2}, ...].`;

    try {
      let messages: ApiMessageType[] = [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: userPrompt,
        },
      ];

      console.log("MESSAGES", messages);
      const response = await processChat({ messages });
      console.log("RESPONSE", response);
      if (!response) throw new Error("No response");
      let dataObject = JSON.parse(response);
      return dataObject.map((item: CharacterType) => ({ ...item }));
    } catch (error) {
      console.error("Error generating items: ", error);
      return null;
    }
  };

  return (
    <CollectionList
      collectionName={`users/${state.uid}/stories/${storyId}/characters`}
      title="Characters"
      baseUrl={`/story/${storyId}/characters/`}
      generateMultipleItems={generateMultipleItems}

      // systemPrompt={`Generate a list of characters on the Larry Brooks "Story Engineering" methodology for the following story:\n Titled ${story.name}\nSynopsis:\n${story.synopsis}.`}
      // userPrompt={`Please start directly with the list of characters, with no introductory greetings or concluding salutations. Return the character list in a valid JSON format parsable with JSON.parse as follows: [{"name":"character 1 name", "summary":"character 1 summary", "order": 1}, {"name":"character 2 name", "summary":"character 2 summary", "order": 2}, ...].`}
    />
  );
}
