import { useEffect } from "react";

declare global {
  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__?: {
      [key: string]: unknown;
      renderers?: Map<any, any>;
    };
  }
}

export const useDisableDevTools = () => {
  useEffect(() => {
    const isFunction = (obj: any) => typeof obj === "function";
    const isObject = (obj: any) => {
      const type = typeof obj;
      return type === "function" || (type === "object" && !!obj);
    };
    const hasWindowObject = () =>
      typeof window !== "undefined" && window.document;

    if (hasWindowObject()) {
      // Ensure the React Developer Tools global hook exists
      if (!isObject(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
        return;
      }

      // Replace all global hook properties with a no-op function or a null value
      for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
        if (prop === "renderers") {
          // prevents console error when dev tools try to iterate of renderers
          window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
          continue;
        }
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = isFunction(
          window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop]
        )
          ? Function.prototype
          : null;
      }
    }
  }, []);
};
