import { saveAs } from "file-saver";
import { Packer, Paragraph, TextRun, Document } from "docx";
import { SectionType } from "../typings/SectionType";

import getFilenameDate from "./getFilenameDate";

async function generateDocx(
  sectionList: SectionType[],
  filename: string
): Promise<void> {
  const paragraphs: Paragraph[] = [];

  sectionList.forEach((section) => {
    // Title
    paragraphs.push(
      new Paragraph({
        children: [
          new TextRun({
            text: `${section.section}. ${section.title}`,
            bold: true,
            size: 32, // adjust size as needed
          }),
        ],
      })
    );
    paragraphs.push(new Paragraph({}));

    // Content
    if (section.content) {
      const contentParagraphs = section.content
        .split("\n")
        // .filter((contentSegment) => contentSegment.trim() !== "") // remove empty strings
        .map(
          (contentSegment) =>
            new Paragraph({
              children: [
                new TextRun({
                  text: contentSegment,
                  bold: false,
                  size: 24, // adjust size as needed
                }),
              ],
            })
        );
      paragraphs.push(...contentParagraphs);
    }

    // Add a blank paragraph as a separator
    paragraphs.push(new Paragraph({}));
  });

  const doc = new Document({
    sections: [
      {
        properties: {},
        children: paragraphs, // Add the list of paragraphs to the document
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    console.log(blob);
    saveAs(blob, getFilenameDate(filename));
    console.log("Document created successfully");
  });
}

export default generateDocx;
