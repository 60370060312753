import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authState } from "../redux/authSlice";
import logo from "../assets/plotify_black_t.png";
import LoginSmall from "./LoginSmall";

export default function Home() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const state = useSelector(authState);
  return (
    <div className="flex flex-col flex-1 p-5 space-y-5 bg-white">
      <img className="object-contain w-full h-48" src={logo} alt="logo" />

      <div className="max-w-lg mx-auto text-center">
        <h1 className="mb-4 text-4xl font-bold">Plotify.ai</h1>

        <h2 className="mb-6 text-2xl font-semibold">
          Unleash Your Writing Genius
        </h2>

        <div className="px-4">
          <h3 className="mb-3 text-xl font-medium">
            Jumpstart Your Novel-Writing Journey
          </h3>
          <p className="mb-4">
            Think of Plotify.ai as your personal writers-room or writing coach.
            Our AI-driven platform helps you develop rich storylines, compelling
            characters, and engaging plot points, laying a strong foundation for
            your novel.
          </p>

          <h3 className="mb-3 text-xl font-medium">
            Transform Your Storytelling
          </h3>
          <p>
            Dive deeper into your characters' journeys, defined by their
            struggles, actions, and transformations. Embrace a structured,
            step-by-step approach to storytelling that accelerates your writing
            process and helps you craft a captivating narrative.
          </p>
        </div>
      </div>

      <div className="pb-7">
        {!state.uid ? (
          <LoginSmall />
        ) : (
          <button
            className="mx-auto btn btn-primary"
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/stories");
            }}
          >
            Write A Story
          </button>
        )}
      </div>
    </div>
  );
}
