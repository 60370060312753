import { useEffect, useState } from "react";
import { db } from "../firebase.config";
import {
  doc,
  onSnapshot,
  updateDoc,
  deleteDoc,
  Timestamp,
  setDoc,
} from "firebase/firestore";

interface RecordData {
  id?: string;
  createdSeconds?: number;
  updatedSeconds?: number;
}

const useDocument = <T extends RecordData>(
  path: string,
  docId: string
): [
  T | null,
  boolean,
  (data: T) => Promise<void>,
  (data: Partial<T>) => Promise<void>,
  () => Promise<void>,
  string | null
] => {
  const [document, setDocument] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!path) {
      setLoading(false);
      return;
    }

    const docRef = doc(db, `${path}/${docId}`);
    const unsubscribe = onSnapshot(
      docRef,
      (snapshot) => {
        setDocument({ id: snapshot.id, ...snapshot.data() } as unknown as T);
        setLoading(false);
      },
      (error) => {
        setError(error.message || "Error fetching document");
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [path, docId]);

  const set = async (data: T) => {
    setError(null);
    if (!path) {
      setError("Invalid collection path");
      return;
    }
    try {
      const timestamp = Timestamp.now();
      const docRef = doc(db, `${path}/${docId}`);

      await setDoc(docRef, {
        ...data,
        id: docRef.id,
        createdSeconds: timestamp.seconds,
        updatedSeconds: timestamp.seconds,
      });

      return null;
    } catch (error: any) {
      setError(error.message || "Error setting document");
      return error.message;
    }
  };

  const update = async (data: Partial<T>) => {
    setError(null);
    if (!path) {
      setError("Invalid collection path");
      return;
    }
    try {
      const docRef = doc(db, `${path}/${docId}`);

      await updateDoc(docRef, {
        ...data,
        updatedSeconds: Timestamp.now().seconds,
      });

      return null;
    } catch (error: any) {
      setError(error.message || "Error updating document");
      return error.message;
    }
  };

  const remove = async () => {
    setError(null);
    if (!path) {
      setError("Invalid collection path");
      return;
    }
    try {
      const docRef = doc(db, `${path}/${docId}`);
      await deleteDoc(docRef);
      return null;
    } catch (error: any) {
      setError(error.message || "Error deleting document");
      return error.message;
    }
  };

  return [document, loading, set, update, remove, error];
};

export default useDocument;
