import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useSelectStory } from "../hooks/useSelectStory";
import CollectionList from "../components/CollectionList";
import { ApiMessageType } from "../typings/ApiMessageType";
import { processChat } from "../utils/processChat";
import { SceneType } from "../typings/SceneType";
import { beatDescriptions } from "../data/beatDescriptions";
import useCollection from "../hooks/useCollection";
import { BeatType } from "../typings/BeatType";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { CharacterType } from "../typings/CharacterType";

export default function Scenes() {
  const state = useSelector(authState);
  const { storyId } = useParams<{ storyId?: string }>();
  const [story, prompt] = useSelectStory(storyId);

  const [beats, , , , , , beatsSummary] = useCollection<BeatType>(
    `users/${state.uid}/stories/${storyId}/beats`
  );

  const [characters, , , , , , charactersSummary] =
    useCollection<CharacterType>(
      `users/${state.uid}/stories/${storyId}/characters`
    );

  const [loadedItems, loading, add, update, remove, error, scenesSummary] =
    useCollection<SceneType>(`users/${state.uid}/stories/${storyId}/scenes`);

  const scenes: SceneType[] = loadedItems
    .filter((item) => item.id)
    .sort((a, b) => (a?.order || 0) - (b?.order || 0));

  if (!state.uid) return <div>No user</div>;
  if (!storyId) return <div>No story</div>;
  if (!story) return <div>Loading</div>;
  if (!beatsSummary) return <div>Loading</div>;
  if (!beats) return <div>Loading</div>;

  console.log("BEATS---------------", beatsSummary);
  console.log("SCENES----------------", scenesSummary);
  console.log("CHARACTERS----------------", charactersSummary);

  function getSceneInstructions(beatName: string): string {
    const beatDescription = beatDescriptions.find(
      (beat) => beat.name.toUpperCase() === beatName.toUpperCase()
    );

    if (!beatDescription) {
      return "No beat found with the provided name";
    }

    let information = `Description: ${beatDescription.description}\n`;

    if (beatDescription.detailedInstructions) {
      information += `Detailed Instructions: ${beatDescription.detailedInstructions}\n\n`;
    }
    information += `Generate exactly ${beatDescription.scenes.length} scenes in this beat.\n`;
    if (beatDescription.sceneInstructions) {
      information += `Scene Instructions: ${beatDescription.sceneInstructions}\n`;
    }

    return information;
  }

  const generateItemsForBeat = async (beat: BeatType) => {
    if (!beat || !beat.name) return null;

    const systemPrompt = `You will generate content for the following story titled: "${story.name}"\n\nSynopsis:\n${story.synopsis}\n\nCharacters:\n${charactersSummary}\n\nHere is the full beatlist for "${story.name}" based on "Save The Cat!" story structure:\n${beatsSummary}\n\n`;

    const userPrompt = `Generate scenes for the ${
      beat.name
    } beat in JSON format as follows: [{"name":"scene name", "summary":"scene summary"}, ...]\n\nEach summary should be 100 words or less\n\n${
      beat.description
        ? `Use these additional details about the ${beat.name} beat: ${beat.description}\n\n`
        : `Use these additional details about the ${beat.name} beat: ${beat.summary}\n\n`
    }Use this guide to determine which scenes to generate for the ${
      beat.name
    } beat:\n${getSceneInstructions(beat.name)}`;

    try {
      let messages: ApiMessageType[] = [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: userPrompt,
        },
      ];

      console.log("MESSAGES", messages);
      const response = await processChat({ messages });
      console.log("RESPONSE", response);
      if (!response) throw new Error("No response");
      let dataObject = JSON.parse(response);
      return dataObject.map((item: SceneType) => ({ ...item }));
    } catch (error) {
      console.error("Error generating items: ", error);
      return null;
    }
  };

  const generateMultipleItems = async () => {
    let sceneList: SceneType[] = [];
    let sceneNumber = 0;

    for (let beat of beats) {
      let items = await generateItemsForBeat(beat);
      if (items) {
        for (let item of items) {
          console.log("ITEM", item);
          sceneNumber++;
          sceneList.push({
            ...item,
            beatId: beat.id,
            beatName: beat.name,
            order: sceneNumber,
          });
        }
      }
    }

    console.log("SCENELIST", sceneList);
    return sceneList;
  };

  function getFieldDefinitions(scene: SceneType) {
    if (
      prompt &&
      story &&
      scenesSummary &&
      scene &&
      scene.name &&
      scene.description &&
      charactersSummary
    ) {
      const fieldDefinitions: FieldDefinitionType[] = [
        {
          field: "description",
          name: "Detailed Description",
          type: "textarea",
          minRows: 4,
          systemPrompt:
            prompt +
            `\n\nScenes:\n` +
            scenesSummary +
            `\n\nCharacters\n` +
            charactersSummary,
          userPrompt: `Write a 500-word detailed overview of the "${scene?.name}" scene of the "${story.name}" story, focusing solely on the content without any titles or headings.`,
          relatedFields: ["summary", "description"],
        },
        {
          field: "content",
          name: "Full Content",
          type: "textarea",
          minRows: 6,
          systemPrompt: `Here is the content for the "${scene.name}" scene of the "${story.name}" story:\n\n${scene.description}`,
          userPrompt: `Rewrite this as a scene for a novel with a word count of approximately 1500 words. Ensure that the scene is well-written, includes dialogue, advances the story, and focuses solely on the content without any titles or headings.`,
          relatedFields: [],
        },
      ];

      return fieldDefinitions;
    } else return null;
  }

  function getTitleFieldDefinitions(scene: SceneType) {
    if (prompt && story && scenesSummary && scene && scene.name) {
      const fieldDefinitions: FieldDefinitionType[] = [
        {
          field: "name",
          name: "Name",
          type: "text",
          minRows: 1,
          systemPrompt: prompt + `\n\nScenes:\n` + scenesSummary,
          userPrompt: `Suggest a better name for the "${scene?.name}" scene of the "${story.name}" story. Provide a 2 to 3 word scene name only, without any quotation marks and without any punctuation.`,
          relatedFields: ["summary", "description"],
        },
      ];

      return fieldDefinitions;
    } else return null;
  }

  const generateAutoIdea = async (
    systemPromptInput: string,
    userPromptInput: string,
    relatedFields: string[],
    promptHelp: string,
    scene: SceneType
  ) => {
    if (!systemPromptInput || !userPromptInput) return;

    const systemPrompt = systemPromptInput;

    const related = relatedFields
      ?.map((relatedField) => {
        if (scene[relatedField as keyof SceneType]?.toString()) {
          return `${relatedField}: ${scene[
            relatedField as keyof SceneType
          ]?.toString()}\n\n`;
        } else {
          return "";
        }
      })
      .join("");

    let userPrompt = userPromptInput + "\n\n";
    if (related.length > 0) userPrompt += "Additional information: " + related;
    if (promptHelp)
      userPrompt +=
        "Follow these priority instructions in your response: " + promptHelp;
    console.log("SYSTEM PROMPT", systemPrompt);
    console.log("USER PROMPT", userPrompt);

    try {
      let messages: ApiMessageType[] = [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: userPrompt,
        },
      ];

      console.log("MESSAGES", messages);

      const response = await processChat({ messages });
      console.log("RESPONSE", response);

      if (!response) throw new Error("No response");

      return response;
    } catch (error) {
      console.error("Error generating items: ", error);
      return null;
    }
  };

  async function generateScenes() {
    for (const scene of scenes) {
      if (!scene.id) return;
      const fieldDefinitions = getFieldDefinitions(scene);
      if (fieldDefinitions) {
        for (const fieldData of fieldDefinitions) {
          const { systemPrompt, userPrompt, relatedFields } = fieldData;
          if (systemPrompt && userPrompt) {
            const response = await generateAutoIdea(
              systemPrompt,
              userPrompt,
              relatedFields || [],
              "",
              scene
            );
            if (response) {
              const updatedScene = { ...scene, [fieldData.field]: response };
              update(scene.id, updatedScene);
            }
          }
        }
      }
    }
  }

  async function generateTitles() {
    for (const scene of scenes) {
      if (!scene.id) return;
      const fieldDefinitions = getTitleFieldDefinitions(scene);
      if (fieldDefinitions) {
        for (const fieldData of fieldDefinitions) {
          const { systemPrompt, userPrompt, relatedFields } = fieldData;
          if (systemPrompt && userPrompt) {
            const response = await generateAutoIdea(
              systemPrompt,
              userPrompt,
              relatedFields || [],
              "",
              scene
            );
            if (response) {
              const updatedScene = { ...scene, [fieldData.field]: response };
              update(scene.id, updatedScene);
            }
          }
        }
      }
    }
  }

  return (
    <CollectionList
      collectionName={`users/${state.uid}/stories/${storyId}/scenes`}
      title="Scenes"
      baseUrl={`/story/${storyId}/scenes/`}
      generateMultipleItems={generateMultipleItems}
      generateAutopilot={generateScenes}
    />
  );
}
