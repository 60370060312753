import { functions } from "../firebase.config";
import { httpsCallable } from "firebase/functions";

const checkAdminApi = httpsCallable(functions, "checkAdmin");

type CheckAdminType = {
  uid: string;
};

export const checkAdmin = async (data: CheckAdminType) => {
  try {
    const response: any = await checkAdminApi(data);
    return response.data;
  } catch (error) {
    console.log("ERROR", error);
    return undefined;
  }
};
