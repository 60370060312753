import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useParams } from "react-router-dom";
import { BeatType, defaultBeat } from "../typings/BeatType";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { useSelectStory } from "../hooks/useSelectStory";
import DocumentForm from "../components/DocumentForm";
import { useEffect, useState } from "react";
import useCollection from "../hooks/useCollection";
import useDocument from "../hooks/useDocument";
import { beatDescriptions } from "../data/beatDescriptions";

export default function Beat() {
  const { storyId, beatId } = useParams<{
    storyId?: string;
    beatId?: string;
  }>();
  const [story, prompt] = useSelectStory(storyId);
  const state = useSelector(authState);

  const [, , , , , , summary] = useCollection<BeatType>(
    `users/${state.uid}/stories/${storyId}/beats`
  );
  const [beat, , , , ,] = useDocument<BeatType>(
    `users/${state.uid}/stories/${storyId}/beats`,
    beatId || ""
  );

  console.log(summary);
  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  function getBeatInstructions(beatName: string): string {
    const beatDescription = beatDescriptions.find(
      (beat) => beat.name === beatName
    );

    if (!beatDescription) {
      return "No beat found with the provided name";
    }

    let information = `Description: ${beatDescription.description}\n`;
    if (beatDescription.detailedInstructions) {
      information += `Detailed Instructions: ${beatDescription.detailedInstructions}\n`;
    }

    return information;
  }

  function getSceneInstructions(beatName: string): string {
    const beatDescription = beatDescriptions.find(
      (beat) => beat.name === beatName
    );

    if (!beatDescription) {
      return "No beat found with the provided name";
    }

    let information = `Description: ${beatDescription.description}\n`;

    if (beatDescription.detailedInstructions) {
      information += `Detailed Instructions: ${beatDescription.detailedInstructions}\n`;
    }
    if (beatDescription.sceneInstructions) {
      information += `Scene Instructions: ${beatDescription.sceneInstructions}\n`;
    }
    if (beatDescription.targetLength) {
      information += `Target Length: ${beatDescription.targetLength}\n`;
    }

    return information;
  }

  useEffect(() => {
    if (prompt && story && summary && beat && beat.name) {
      setFieldDefinitions([
        { field: "name", name: "Name", type: "text", minRows: 1 },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 2,
          systemPrompt:
            prompt + "\n\nSave The Cat! Beatlist Summary:\n" + summary,
          userPrompt: `Based on the "Save The Cat!" story structure, write a 60-word summary of the "${
            beat.name
          }" beat.\n\nHere is guidance on the "${
            beat.name
          }" beat:\n\n${getBeatInstructions(beat.name)}`,
          relatedFields: ["summary", "description"],
        },
        {
          field: "description",
          name: "Description",
          type: "textarea",
          minRows: 4,
          systemPrompt:
            prompt + "\n\nSave The Cat! Beatlist Summary:\n" + summary,
          userPrompt: `Based on the "Save The Cat!" story structure, write a detailed treatment of the "${
            beat.name
          }" beat.\n\nHere is guidance on the "${
            beat.name
          }" beat:\n\n${getBeatInstructions(beat.name)}`,
          relatedFields: ["summary", "description"],
        },

        {
          field: "scenesSummary",
          name: "Scenes Summary",
          type: "textarea",
          minRows: 3,
          systemPrompt:
            prompt + "\n\nSave The Cat! Beatlist Summary:\n" + summary,
          userPrompt: `Based on the "Save The Cat!" story structure, list the scenes contained in the "${
            beat.name
          }" beat with a 100-word description of each scene.\n\nHere is guidance on what scenes belong in the "${
            beat.name
          }" beat:\n\n${getSceneInstructions(beat.name)}`,
          relatedFields: ["summary", "description"],
        },

        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },
      ]);
    }
  }, [beat, prompt, story, summary]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {story && (
        <div className="text-2xl font-semibold">
          Selected Story: {story.name}
        </div>
      )}
      {state.uid && fieldDefinitions && beatId && storyId && story && beat && (
        <DocumentForm<BeatType>
          initialValues={defaultBeat}
          fieldDefinitions={fieldDefinitions}
          title={`Selected Beat`}
          collectionName={`users/${state.uid}/stories/${storyId}/beats/`}
          docId={beatId}
        />
      )}
    </div>
  );
}
