import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CharacterType, defaultCharacter } from "../typings/CharacterType";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { useSelectStory } from "../hooks/useSelectStory";
import DocumentForm from "../components/DocumentForm";

export default function Character() {
  const { storyId, characterId } = useParams<{
    storyId?: string;
    characterId?: string;
  }>();
  const [story, prompt] = useSelectStory(storyId);
  const state = useSelector(authState);
  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  useEffect(() => {
    if (prompt && story) {
      const allFields = [
        "name",
        "summary",
        "description",
        "role",
        "backstory",
        "motivation",
        "goal",
        "conflict",
        "arc",
        "traits",
      ];
      setFieldDefinitions([
        { field: "name", name: "Name", type: "text", minRows: 1 },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 2,
          systemPrompt: prompt,
          userPrompt:
            "Write a summary of the following character in 100 words or less:",
          relatedFields: allFields,
        },
        {
          field: "description",
          name: "Description",
          type: "textarea",
          minRows: 4,
          systemPrompt: prompt,
          userPrompt:
            "Write a detailed description of the following character:",
          relatedFields: allFields,
        },
        {
          field: "role",
          name: "Role",
          type: "text",
          minRows: 1,
          systemPrompt: prompt,
          userPrompt:
            "Describe the role of the following character in 10 words or less:",
          relatedFields: allFields,
        },

        {
          field: "backstory",
          name: "Backstory",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt,
          userPrompt: `Describe the backstory of the following character in one paragraph with no heading:`,
          relatedFields: allFields,
        },
        {
          field: "motivation",
          name: "Motivation",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt,
          userPrompt: `Describe the motivation of the following character in one paragraph with no heading:`,
          relatedFields: allFields,
        },
        {
          field: "goal",
          name: "Goal",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt,
          userPrompt: `Describe the goal of the following character in one paragraph with no heading:`,
          relatedFields: allFields,
        },
        {
          field: "conflict",
          name: "Conflict",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt,
          userPrompt: `Describe the core conflict of the following character in one paragraph with no heading:`,
          relatedFields: allFields,
        },
        {
          field: "arc",
          name: "Arc",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt,
          userPrompt: `Describe the arc of the following character in one paragraph with no heading:`,
          relatedFields: allFields,
        },
        {
          field: "traits",
          name: "Traits",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt,
          userPrompt: `Describe the traits of the following character in one paragraph with no heading:`,
          relatedFields: allFields,
        },
        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },
      ]);
    }
  }, [prompt, story]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {story && (
        <div className="text-2xl font-semibold">
          Selected Story: {story.name}
        </div>
      )}
      {state.uid && characterId && storyId && fieldDefinitions && (
        <DocumentForm<CharacterType>
          initialValues={defaultCharacter}
          fieldDefinitions={fieldDefinitions}
          title={`Selected Character`}
          collectionName={`users/${state.uid}/stories/${storyId}/characters/`}
          docId={characterId}
        />
      )}
    </div>
  );
}
