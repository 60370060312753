export type SceneType = {
  // Editable Properties
  name?: string; // The title or name of the chapter.
  summary?: string; // A brief overview or summary of the chapter.
  description?: string; // A longer description of the chapter.
  content?: string; // The full text of the chapter.

  // Scene properties
  sceneGoal?: string; // What the character wants at the beginning of the scene.
  sceneConflict?: string; // The obstacles that prevent the character from achieving their goal.
  sceneDisaster?: string; // The failure of the character to reach their goal.

  // Sequel properties
  sequelReaction?: string; // Emotional response to the disaster.
  sequelDilemma?: string; // A situation with no good options.
  sequelDecision?: string; // The character makes a choice (which leads to the next Goal).

  setting?: string; // Specific location or time where the chapter events happen.
  characters?: string; // List of characters that appear in the chapter.
  themes?: string; // The underlying themes or messages in the chapter.
  notes?: string; // Author's notes about the chapter, could be research or plot related.

  // Read-Only Properties
  order?: number; // The number of the chapter within the story.
  wordCount?: number; // The total number of words in the chapter.
  createdSeconds?: number; // The timestamp for when the chapter was created.
  updatedSeconds?: number; // The timestamp for when the chapter was last updated.
  locked?: boolean; // Whether the chapter is locked for editing.

  // ID Properties
  id?: string; // The unique identifier for the chapter.
  storyId?: string; // The unique identifier of the story the chapter belongs to.
  beatId?: string; // The STC beat the chapter corresponds to.
  beatName?: string; // The name of the STC beat the chapter corresponds to.
};

export const defaultScene: SceneType = {
  name: "", // The title or name of the chapter.
  summary: "", // A brief overview or summary of the chapter.
  content: "", // The full text of the chapter.

  // Scene properties
  sceneGoal: "", // What the character wants at the beginning of the scene.
  sceneConflict: "", // The obstacles that prevent the character from achieving their goal.
  sceneDisaster: "", // The failure of the character to reach their goal.

  // Sequel properties
  sequelReaction: "", // Emotional response to the disaster.
  sequelDilemma: "", // A situation with no good options.
  sequelDecision: "", // The character makes a choice (which leads to the next Goal).

  setting: "", // Specific location or time where the chapter events happen.
  characters: "", // List of characters that appear in the chapter.
  themes: "", // The underlying themes or messages in the chapter.
  notes: "", // Author's notes about the chapter, could be research or plot related.
};
