export type BeatType = {
  //Editable Properties
  name?: string; // The name of the beat (e.g., "Opening Image", "Catalyst").
  summary?: string; // A brief summary of the events in this beat.
  description?: string; // A description of what typically happens in this beat.
  act?: string; // The act in which this beat occurs (e.g., "Act 1", "Act 2", "Act 3").
  scenesSummary?: string; // Brief descriptions of the scenes that occur during this beat.
  conflict?: string; // The conflict that occurs during this beat.
  notes?: string; // Any additional notes or reminders about this beat.

  //Read-Only Properties
  storyId?: string; // The unique identifier of the story the beat belongs to.
  chapterIds?: string[]; // The unique identifiers of chapters associated with this beat.
  order?: number; // The position of the beat within the sequence of beats.
  wordCount?: number; // The suggested or actual word count for this beat.
  createdSeconds?: number; // The timestamp for when the beat was created.
  updatedSeconds?: number; // The timestamp for when the beat was last updated.
  locked?: boolean; // Whether the beat is locked for editing.
  id?: string; // The unique identifier for the beat.
};

export const defaultBeat: BeatType = {
  name: "", // The name of the beat (e.g., "Opening Image", "Catalyst").
  summary: "", // A brief summary of the events in this beat.
  description: "", // A description of what typically happens in this beat.
  act: "", // The act in which this beat occurs (e.g., "Act 1", "Act 2", "Act 3").
  scenesSummary: "", // Brief descriptions of the scenes that occur during this beat.
  conflict: "", // The conflict that occurs during this beat.
  notes: "", // Any additional notes or reminders about this beat.
};
