import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useParams } from "react-router-dom";
import { SceneType, defaultScene } from "../typings/SceneType";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import DocumentForm from "../components/DocumentForm";
import { useSelectStory } from "../hooks/useSelectStory";
import { useEffect, useState } from "react";
import useCollection from "../hooks/useCollection";
import useDocument from "../hooks/useDocument";

export default function Scene() {
  const { storyId, sceneId } = useParams<{
    storyId?: string;
    sceneId?: string;
  }>();
  const [story, prompt] = useSelectStory(storyId);
  const state = useSelector(authState);
  const [scene, , , , ,] = useDocument<SceneType>(
    `users/${state.uid}/stories/${storyId}/scenes`,
    sceneId || ""
  );
  const [loadedItems, loading, add, update, remove, error, scenesSummary] =
    useCollection<SceneType>(`users/${state.uid}/stories/${storyId}/scenes`);

  const scenes: SceneType[] = loadedItems
    .filter((item) => item.id)
    .sort((a, b) => (a?.order || 0) - (b?.order || 0));

  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  console.log("SCENES=========", scenes);
  console.log("SCENE=========", scene);

  useEffect(() => {
    if (
      prompt &&
      story &&
      scenesSummary &&
      scene &&
      scene.name &&
      story.name &&
      scene.description
    ) {
      setFieldDefinitions([
        {
          field: "name",
          name: "Name",
          type: "text",
          minRows: 1,
          systemPrompt: prompt + `\n\nScenes:\n` + scenesSummary,
          userPrompt: `Suggest a better name for the "${scene?.name}" scene of the "${story.name}" story. Provide a 2 to 3 word scene name only, without any quotation marks and without any punctuation.`,
          relatedFields: ["summary", "description"],
        },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 2,
          systemPrompt: prompt + `\n\nScenes:\n` + scenesSummary,
          userPrompt: `Write a 60-word summary of the "${scene?.name}" scene of the "${story.name}" story.`,
          relatedFields: ["summary", "description"],
        },
        {
          field: "description",
          name: "Detailed Description",
          type: "textarea",
          minRows: 4,
          systemPrompt: prompt + `\n\nScenes:\n` + scenesSummary,
          userPrompt: `Write a 500-word detailed overview of the "${scene?.name}" scene of the "${story.name}" story, focusing solely on the content without any titles or headings.`,
          relatedFields: ["summary", "description"],
        },
        {
          field: "content",
          name: "Full Content",
          type: "textarea",
          minRows: 6,
          systemPrompt: `Here is the content for the "${scene?.name}" scene of the "${story.name}" story:\n\n${scene.description}`,
          userPrompt: `Rewrite this as a scene for a novel with a word count of approximately 1500 words. Ensure that the scene is well-written, includes dialogue, advances the story, and focuses solely on the content without any titles or headings.`,
          relatedFields: [],
        },
        {
          field: "sceneGoal",
          name: "Scene Goal",
          type: "textarea",
          minRows: 3,
        },
        {
          field: "sceneConflict",
          name: "Scene Conflict",
          type: "textarea",
          minRows: 3,
        },
        {
          field: "sceneDisaster",
          name: "Scene Disaster",
          type: "textarea",
          minRows: 3,
        },
        {
          field: "sequelReaction",
          name: "Sequel Reaction",
          type: "textarea",
          minRows: 3,
        },
        {
          field: "sequelDilemma",
          name: "Sequel Dilemma",
          type: "textarea",
          minRows: 3,
        },
        {
          field: "sequelDecision",
          name: "Sequel Decision",
          type: "textarea",
          minRows: 3,
        },

        { field: "setting", name: "Setting", type: "textarea", minRows: 3 },
        {
          field: "characters",
          name: "Characters",
          type: "textarea",
          minRows: 3,
        },
        { field: "themes", name: "Themes", type: "textarea", minRows: 3 },
        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },
      ]);
    }
  }, [prompt, scene, scene?.description, scene?.name, scenesSummary, story]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {story && (
        <div className="text-2xl font-semibold">
          Selected Story: {story.name}
        </div>
      )}
      {state.uid &&
        fieldDefinitions &&
        storyId &&
        sceneId &&
        story &&
        scene && (
          <DocumentForm<SceneType>
            initialValues={defaultScene}
            fieldDefinitions={fieldDefinitions}
            title={`Selected Scene`}
            collectionName={`users/${state.uid}/stories/${storyId}/scenes/`}
            docId={sceneId}
          />
        )}
    </div>
  );
}
