import { useEffect, useState } from "react";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { isEqual, set } from "lodash";
import { LockClosed } from "../icons/LockClosed";
import { LockOpen } from "../icons/LockOpen";
import TextareaAutosize from "react-textarea-autosize";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import { ApiMessageType } from "../typings/ApiMessageType";
import { processChat } from "../utils/processChat";
import { PulseLoader } from "react-spinners";

interface InputProps {
  name?: string;
  summary?: string;
  locked?: boolean;
}

type FormProps<T extends InputProps> = {
  fieldDefinitions: FieldDefinitionType[];
  initialValues: T;
  onSubmit?: (values: T) => Promise<void> | void;
  locked?: boolean;
  title: string;
  collectionName: string;
  docId: string;
};

export default function DocumentForm<T extends InputProps>({
  fieldDefinitions,
  initialValues,
  title,
  collectionName,
  docId,
}: FormProps<T>) {
  const [startValues, setStartValues] = useState<T>(initialValues);
  const [values, setValues] = useState<T>(initialValues);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [fieldOpen, setFieldOpen] = useState<string | null>(null);
  const [promptHelp, setPromptHelp] = useState<string | null>(null);
  const [generating, setGenerating] = useState<boolean>(false);
  const [rewriting, setRewriting] = useState<boolean>(false);
  const [generatedItem, setGeneratedItem] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value, type } = e.target;
    const inputValue =
      type === "checkbox" ? (e.target as HTMLInputElement).checked : value;
    setValues((prevValues) => ({ ...prevValues, [name]: inputValue }));
  };

  const toggleLock = async () => {
    const updatedLocked = values["locked" as keyof T] || false;
    console.log("TOGGLE LOCK", updatedLocked);
    if (docId && collectionName) {
      await updateDoc(doc(db, collectionName, docId), {
        locked: updatedLocked ? false : true,
      });
      setValues((prevValues) => ({ ...prevValues, locked: !updatedLocked }));
    }
  };

  const handleFormSubmit = async () => {
    if (collectionName && docId) {
      setSaving(true);
      const updateData = { ...values } as unknown as { [x: string]: string };
      await updateDoc(doc(db, collectionName, docId), updateData);
      setSaving(false);
    }
  };

  const generateIdeas = async (
    systemPromptInput: string,
    userPromptInput: string,
    relatedFields: string[],
    promptHelp: string
  ) => {
    if (!systemPromptInput || !userPromptInput) return;

    const systemPrompt = systemPromptInput;

    const related = relatedFields
      ?.map((relatedField) => {
        if (values[relatedField as keyof T]?.toString()) {
          return `${relatedField}: ${values[
            relatedField as keyof T
          ]?.toString()}\n\n`;
        } else {
          return "";
        }
      })
      .join("");

    let userPrompt = userPromptInput + "\n\n";
    if (related) userPrompt += "Additional information: " + related;
    if (promptHelp)
      userPrompt += "Additional priority instructions: " + promptHelp;
    console.log("SYSTEM PROMPT", systemPrompt);
    console.log("USER PROMPT", userPrompt);

    setGenerating(true);

    try {
      let messages: ApiMessageType[] = [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: userPrompt,
        },
      ];

      console.log("MESSAGES", messages);

      const response = await processChat({ messages });
      console.log("RESPONSE", response);

      if (!response) throw new Error("No response");
      setGenerating(false);

      setGeneratedItem(response);
    } catch (error) {
      setGenerating(false);
      console.error("Error generating items: ", error);
    }
  };

  const rewriteIdeas = async (
    systemPromptInput: string,
    userPromptInput: string,
    existingField: string,
    promptHelp: string
  ) => {
    if (!systemPromptInput || !userPromptInput) return;

    const systemPrompt = systemPromptInput;

    let userPrompt = "Rewrite this: " + existingField + "\n\n";
    if (promptHelp) userPrompt += "Based on the following notes: " + promptHelp;

    console.log("SYSTEM PROMPT", systemPrompt);
    console.log("USER PROMPT", userPrompt);

    setRewriting(true);

    try {
      let messages: ApiMessageType[] = [
        {
          role: "system",
          content: systemPrompt,
        },
        {
          role: "user",
          content: userPrompt,
        },
      ];

      console.log("MESSAGES", messages);

      const response = await processChat({ messages });
      console.log("RESPONSE", response);

      if (!response) throw new Error("No response");
      setRewriting(false);

      setGeneratedItem(response);
    } catch (error) {
      setRewriting(false);
      console.error("Error generating items: ", error);
    }
  };

  useEffect(() => {
    const getSelectedDocument = async () => {
      const unsubscribe = onSnapshot(
        doc(db, collectionName, docId),
        async (snapshot) => {
          if (snapshot.exists()) {
            setStartValues(snapshot.data() as T);
            setValues(snapshot.data() as T);
          }
        }
      );
      return () => unsubscribe();
    };
    if (docId && collectionName) {
      getSelectedDocument();
    }
  }, [docId, collectionName]);

  useEffect(() => {
    const handleFormSubmit = async () => {
      if (collectionName && docId) {
        setSaving(true);
        const updateData = { ...values } as unknown as { [x: string]: string };
        await updateDoc(doc(db, collectionName, docId), updateData);
        setSaving(false);
      }
    };

    setHasChanges(!isEqual(values, startValues));
    if (!isEqual(values, startValues)) {
      handleFormSubmit();
    }
  }, [collectionName, docId, startValues, values]);

  return (
    <div className="flex flex-col space-y-3 text-black bg-white">
      <div className="flex items-center justify-between">
        <div className="text-2xl font-semibold">
          {title}: {values?.name || ""}
        </div>
        <button onClick={toggleLock}>
          {values.locked ? LockClosed : LockOpen}
        </button>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
        className="flex flex-col space-y-3 text-black bg-white"
      >
        {fieldDefinitions.map(
          (
            {
              field,
              name,
              type,
              minRows,
              systemPrompt,
              userPrompt,
              relatedFields,
            },
            index
          ) => (
            <div
              className={`flex flex-col ${
                fieldOpen === field ? "bg-white" : "bg-white"
              }`}
              key={index}
            >
              <div className="flex justify-between">
                <label htmlFor={field} className="px-2 text-sm font-light">
                  {name}
                </label>
                {systemPrompt && (
                  <button
                    className="text-sm font-light"
                    onClick={() => {
                      setFieldOpen(fieldOpen === field ? null : field);
                      setPromptHelp(null);
                      setGeneratedItem("");
                    }}
                  >
                    {fieldOpen === field ? "-" : "+"}
                  </button>
                )}
              </div>
              {type === "textarea" ? (
                <TextareaAutosize
                  minRows={minRows}
                  id={field}
                  name={field}
                  value={values[field as keyof T]?.toString() || ""}
                  onChange={handleInputChange}
                  className="w-full px-2 py-1 border rounded-md"
                  disabled={values.locked}
                />
              ) : (
                <input
                  type={type}
                  id={field}
                  name={field}
                  value={values[field as keyof T]?.toString() || ""}
                  onChange={handleInputChange}
                  className="w-full px-2 py-1 border rounded-md"
                  disabled={values.locked}
                />
              )}
              {fieldOpen === field && (
                <div className="flex flex-col p-2 mt-2 space-y-3 whitespace-pre-wrap bg-slate-200">
                  {/* <div>{systemPrompt}</div>
                  <div>
                    <div className="mt-3">{userPrompt}</div>
                    {relatedFields?.map((relatedField, index) => (
                      <div key={index}>
                        {values[relatedField as keyof T]?.toString() && (
                          <div>
                            {relatedField}:{" "}
                            {values[relatedField as keyof T]?.toString()}
                            {"\n\n"}
                          </div>
                        )}
                      </div>
                    ))}
                    {promptHelp && (
                      <div>additional instructions: {promptHelp}</div>
                    )}
                  </div> */}
                  <TextareaAutosize
                    minRows={2}
                    id="promptHelp"
                    name="promptHelp"
                    placeholder="Prompt Help"
                    value={promptHelp || ""}
                    onChange={(e) => setPromptHelp(e.target.value)}
                    className="w-full px-2 py-1 border rounded-md outline-none"
                  />
                  <div className="flex space-x-1">
                    <button
                      onClick={() =>
                        generateIdeas(
                          systemPrompt || "",
                          userPrompt || "",
                          relatedFields || [],
                          promptHelp || ""
                        )
                      }
                      disabled={generating || rewriting}
                      className="px-3 py-1 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:hover:bg-blue-500"
                    >
                      {generating ? (
                        <PulseLoader color="white" size={8} />
                      ) : (
                        "Generate"
                      )}
                    </button>

                    <button
                      onClick={() =>
                        rewriteIdeas(
                          systemPrompt || "",
                          userPrompt || "",
                          values[field as keyof T]?.toString() || "",
                          promptHelp || ""
                        )
                      }
                      disabled={generating || rewriting}
                      className="px-3 py-1 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:hover:bg-blue-500"
                    >
                      {rewriting ? (
                        <PulseLoader color="white" size={8} />
                      ) : (
                        "Rewrite"
                      )}
                    </button>
                  </div>

                  {generatedItem && (
                    <div
                      onClick={() => {
                        setValues((prevValues) => ({
                          ...prevValues,
                          [field]: generatedItem,
                        }));
                        setGeneratedItem("");
                        setPromptHelp("");
                        setFieldOpen(null);
                      }}
                      className="whitespace-pre-wrap cursor-pointer"
                    >
                      {generatedItem}
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        )}

        <button
          className="px-4 py-2 mt-4 text-white bg-blue-500 rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
          type="submit"
          disabled={!hasChanges}
        >
          {saving ? <PulseLoader color="white" size={8} /> : "Save"}
        </button>
      </form>
    </div>
  );
}
