type RowDisplayProps = {
  label: string;
  value: string;
};

export default function RowDisplay({ label, value }: RowDisplayProps) {
  return (
    <div className="grid items-center grid-cols-2 gap-5">
      <div className="text-right">{label}</div>
      <div
        className="px-3 py-2 overflow-scroll text-black bg-gray-300 rounded-md"
        id="name-field"
      >
        {value}
      </div>
    </div>
  );
}
