import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authState } from "../redux/authSlice";
import { useSelectStory } from "../hooks/useSelectStory";
import CollectionList from "../components/CollectionList";
import { beatDescriptions } from "../data/beatDescriptions";
import { CharacterType } from "../typings/CharacterType";
import useCollection from "../hooks/useCollection";

export default function Beats() {
  const state = useSelector(authState);
  const { storyId } = useParams<{ storyId?: string }>();
  const [story] = useSelectStory(storyId);
  const [characters, , , , , , charactersSummary] =
    useCollection<CharacterType>(
      `users/${state.uid}/stories/${storyId}/characters`
    );
  const beatlistGuide = beatDescriptions
    .map((beat) => {
      return `${beat.name}: ${beat.description}`;
    })
    .join("\n");

  console.log(beatlistGuide);

  if (!state.uid) return <div>No user</div>;
  if (!storyId) return <div>No story</div>;
  if (!story) return <div>Loading</div>;

  return (
    <CollectionList
      collectionName={`users/${state.uid}/stories/${storyId}/beats`}
      title="Beats"
      baseUrl={`/story/${storyId}/beats/`}
      systemPrompt={`Generate a beatsheet of 15 beat summaries based on the Save The Cat! methodology for the following story:\n Titled ${story.name}\nSynopsis:\n${story.synopsis}.\n\nCharacters:\n${charactersSummary}\n\nThe beatsheet should include the following beats:\n${beatlistGuide}`}
      userPrompt={`Each beat summary should at least 70 words. Return the beatsheet in JSON format as follows: [{"name":"beat 1 name", "summary":"beat 1 summary", "order": 1}, {"name":"beat 2 name", "summary":"beat 2 summary", "order": 2}, ...].`}
    />
  );
}
