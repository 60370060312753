import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBDomckDlt5X8vEQ2oyMTFfkHdi7qYl0c0",
  authDomain: "plotify.ai",
  projectId: "plotifyai",
  storageBucket: "plotifyai.appspot.com",
  messagingSenderId: "160042038598",
  appId: "1:160042038598:web:db29d53cf630d03cc42147",
  measurementId: "G-7M3S6TEBTN",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app);

export { db, storage, auth, functions, analytics };
