import { getIdToken, getIdTokenResult } from "firebase/auth";
import { auth } from "../firebase.config";

export default async function isUserPremium(): Promise<boolean> {
  const { currentUser } = auth;
  if (currentUser) {
    await getIdToken(currentUser, true);
    const decodedToken = await getIdTokenResult(currentUser);
    return decodedToken?.claims?.stripeRole ? true : false;
  } else {
    return false;
  }
}
