import { Stripe, loadStripe } from "@stripe/stripe-js";

let stripePromise: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe(
      "pk_live_51NJl0UJRmKAbg9dpbAdSSHStF79OHKyre1cBaOjgr2zopCmX8jelHyGjp9nkt0BsTjHla2Ql0eRU7kuUCVhpXqdQ00maTkqyOW"
    );
  }
  return stripePromise;
};
export default initializeStripe;
