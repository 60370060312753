export type STCBeatType = {
  id: string; // A unique identifier for the beat.
  name: string; // The name of the beat.
  order: number; // The order of the beat in the story structure.
  scenes: number[]; // The chapters associated with this beat.
  description: string; // A brief description of the beat.
  detailedInstructions?: string; // Detailed instructions for writing this beat.
  sceneInstructions: string; // Detailed instructions for writing the scenes associated with this beat.
  example: string; // An example of how this beat can play out in a story.
  targetLength: string; // A guideline for the length this beat should ideally be.
  notes?: string; // Optional notes about the beat.
};

export const beatDescriptions: STCBeatType[] = [
  {
    id: "1",
    name: "Opening Image",
    order: 1,
    scenes: [1],
    description:
      "This is a 'snapshot' of the world as it exists before the events of your story kick into motion.",
    detailedInstructions: `Set the stage with a compelling scene that establishes the mood, setting, and status quo of your protagonist's life. Make sure this scene is visually interesting and immediately grabs the reader's attention.`,
    sceneInstructions: `The "Opening Image" beat should consist of 1 scene:

    Scene 1. Opening Image`,
    example:
      "In 'The Hunger Games', the opening image is of Katniss in the woods, hunting to provide for her family. This establishes her as a survivor and sets up the harsh reality of her world.",
    targetLength: "One scene or 1,000-2,000 words.",
  },
  {
    id: "2",
    name: "Theme Stated",
    order: 2,
    scenes: [2],
    description:
      "The theme of your story is hinted at during a seemingly innocuous moment.",
    detailedInstructions: `The theme can be expressed as a line of dialogue, an event, or an interaction. It should hint at the journey the protagonist will undertake and the lesson they will learn. Remember, the hint should be subtle, not a hammer blow.`,
    sceneInstructions: `The "Theme Stated" beat should consist of 1 scene:
    
    Scene 2. Theme Stated`,
    example:
      "In 'Star Wars: A New Hope', Luke Skywalker gazes longingly at the twin suns of Tatooine, establishing the theme of longing for adventure and a larger purpose.",
    targetLength: "One scene or 1,000-2,000 words.",
  },
  {
    id: "3",
    name: "Set-Up",
    order: 3,
    scenes: [3, 4, 5],
    description:
      "During these chapters, you introduce the protagonist's world, their routine, their relationships, and their needs and desires.",
    detailedInstructions: `Create a compelling portrait of your protagonist's life before the inciting incident disrupts their status quo. This is a chance to provide background information and set up the stakes for your character. Include necessary exposition, but ensure it's engaging and doesn't slow the pace.`,
    sceneInstructions: `The "Set-Up" beat should consist of 3 scenes:
      
    Scene 3. Introduction to the Protagonist: In this scene, we're introduced to our main character in their ordinary world. We see who they are, what they do, and get a sense of their personality, desires, and flaws. It's the "before" picture that gives us a baseline to measure their character growth against later in the story.

    Scene 4. Establishing the Status Quo: This scene showcases the protagonist's daily life. This includes their job, their friends, their family, and other aspects of their regular routine. It gives the audience an understanding of what's normal for the protagonist before the story's main conflict disrupts it.

    Scene 5. Indication of the Upcoming Change: While the actual inciting incident doesn't happen until later, the setup often includes some foreshadowing or hint of the changes to come. This could be a subtle sign of dissatisfaction from the protagonist, a looming threat, or an early hint of the story's central conflict.`,
    example:
      "In 'Harry Potter and the Philosopher's Stone', the set-up involves showing Harry's life with the Dursleys, his strange experiences, and the mysterious letters.",
    targetLength: "3 scenes or 3,000-6,000 words.",
  },
  {
    id: "4",
    name: "Catalyst",
    order: 4,
    scenes: [6],
    description:
      "An event happens that disrupts the protagonist's world and launches them into a new direction.",
    detailedInstructions: `The catalyst should be an event or information that directly impacts the protagonist and propels them out of their comfort zone. It's a point of no return that launches the story's main action.`,
    sceneInstructions: `The "Catalyst" beat should consist of 1 scene:
    
    Scene 6. Catalyst`,
    example:
      "In 'To Kill a Mockingbird', the catalyst is when Atticus is assigned to defend Tom Robinson, a black man accused of rape, disrupting the status quo of Scout and Jem's world.",
    targetLength: "One scene or 1,000-2,000 words.",
  },
  {
    id: "5",
    name: "Debate",
    order: 5,
    scenes: [7, 8, 9],
    description:
      "After the catalyst, the protagonist grapples with what to do next.",
    detailedInstructions: `This beat allows for a period of reflection where the protagonist debates whether they should embark on this new path. Use it to further develop your characters, explore their fears or apprehensions, and to build tension.`,
    sceneInstructions: `The "Debate" beat should consist of 3 scenes:
      
    Scene 7. Realization of the Challenge: The protagonist fully understands the nature of the challenge that has been presented to them. This can involve weighing the pros and cons, understanding the risks involved, or grappling with their own doubts or fears about the journey.

    Scene 8. Consultation or Reflection: The protagonist may consult others, seeking advice or opinions. Alternatively, they could be alone, reflecting on the situation and their decision. This scene often involves significant emotional conflict.

    Scene 9. Decision Point: This is the climax of the debate, where the protagonist makes their decision. Often, some event, realization, or emotional shift pushes them to finally make up their mind. This propels the story into the "Break into Two" beat where they commit to the journey.`,
    example:
      "In 'The Matrix', Neo must decide whether to take the red pill and uncover the truth about the Matrix, or take the blue pill and continue living in ignorance.",
    targetLength: "3 scenes or 3,000-6,000 words.",
  },
  {
    id: "6",
    name: "Break Into Two",
    order: 6,
    scenes: [10],
    description:
      "The protagonist makes a proactive decision to engage with the problem introduced by the catalyst.",
    detailedInstructions: `Show your protagonist making the decision and stepping into a new world or context. This is a critical turning point in the story, often filled with a mix of trepidation and excitement for the protagonist.`,
    sceneInstructions: `The "Break Into Two" beat should consist of 1 scene:
    
    Scene 10. Break Into Two`,
    example:
      "In 'Toy Story', Woody decides to venture out into the human world to rescue Buzz Lightyear, symbolically crossing the threshold into Act Two.",
    targetLength: "One scene or 1,000-2,000 words.",
  },
  {
    id: "7",
    name: "B Story",
    order: 7,
    scenes: [11],
    description:
      "This beat introduces a secondary plotline that reflects or contrasts with the main plot.",
    detailedInstructions: `The B Story often involves a new character or group of characters, and explores a theme or idea that complements the main plot. It can offer a breather from the main story and further enrich your narrative world.`,
    sceneInstructions: `The "B Story" beat should consist of 1 scene:
    
    Scene 11. B Story`,
    example:
      "In 'The Lord of the Rings: The Fellowship of the Ring', the B Story could be seen as the journeys of characters like Aragorn, Legolas, and Gimli, whose stories run parallel to Frodo and Sam's.",
    targetLength: "One scene or 1,000-2,000 words.",
  },
  {
    id: "8",
    name: "Fun and Games",
    order: 8,
    scenes: [12, 13, 14, 15],
    description:
      "This is the part of your story where the protagonist explores the new world they've entered into.",
    detailedInstructions: `It's a chance for your character to grow, learn, and acquire the skills they'll need to succeed. This beat should also deliver on the promise of your premise, showcasing what makes your story unique and entertaining.`,
    sceneInstructions: `The "Fun and Games" beat should consist of 4 scenes:

    Scene 12. Embracing the New World: The protagonist is now fully immersed in the new situation or environment that the story presents. This could be a new job, a magical world, a different time period, a romantic relationship, etc. They begin to explore and experience this new "world."

    Scene 13. Display of Skills/Talents: The protagonist shows their abilities or special qualities that make them equipped to navigate this new world. For example, in a superhero movie, this might be when the hero starts testing their superpowers.

    Scene 14. Initial Successes: The protagonist experiences some early successes or positive experiences in this new world or situation. They might solve some minor problems or overcome initial hurdles, bringing a sense of accomplishment and optimism.

    Scene 15. Joy Ride: This is the height of the "Fun and Games" section, where everything seems to go well for the protagonist. It's the most entertaining or fulfilling part of the new situation or adventure. It's often where the audience sees the premise of the movie fully in play.`,
    example:
      "In 'Star Wars: A New Hope', the Fun and Games beat is seen as the characters go on space adventures, partake in light saber training, and plan their attack on the Death Star.",
    targetLength: "4 scenes or 4,000-8,000 words.",
  },
  {
    id: "9",
    name: "Midpoint",
    order: 9,
    scenes: [16],
    description:
      "This is the point where the story transitions from reaction to action. A significant event occurs that propels the story in a new direction.",
    detailedInstructions: `The midpoint should introduce new information or a new event that raises the stakes, shifts the protagonist's understanding, and pushes the story towards its climax. It often involves a revelation or a reversal that changes the protagonist's goal.`,
    sceneInstructions: `The "Midpoint" beat should consist of 1 scene:
    
    Scene 16. Midpoint`,

    example:
      "In 'The Hunger Games', the midpoint occurs when the rules change and two tributes from the same district can now win, changing Katniss' goal from surviving to protecting Peeta.",
    targetLength: "One scene or 1,000-2,000 words.",
  },
  {
    id: "10",
    name: "Bad Guys Close In",
    order: 10,
    scenes: [17, 18, 19, 20],
    description:
      "The forces opposing the protagonist tighten their grip. Challenges, complications, and obstacles arise, creating a sense of despair.",
    detailedInstructions: `This beat includes both external pressures from antagonistic forces and internal pressures from personal doubts, fears, or flaws. The protagonist's plan starts to unravel, and their situation gets progressively worse, building tension and setting up for the 'All Is Lost' moment.`,
    sceneInstructions: `The "Bad Guys Close In" beat should consist of 2 scenes:
      
    Scene 17. Initial Pressure: The antagonists begin to apply pressure. This might take the form of a direct assault, increased scrutiny, or a sudden disadvantage for the protagonist. It serves as a sign that things are starting to go wrong.

    Scene 18. Protagonist's Response: The protagonist attempts to deal with this new pressure, often underestimating the severity or overestimating their ability to handle it. There may be initial signs of strain in the protagonist or their team's relationship.

    Scene 19. Escalation: The pressure from the antagonists increases. The situation grows more dangerous or the stakes get higher. This could be a potent display of the antagonist's power, a serious setback, or betrayal within the protagonist's team.

    Scene 20. Crisis Point: The mounting pressure leads to a crisis, often resulting in a significant loss or failure for the protagonist. It's not the complete loss associated with the "All is Lost" beat, but it's a significant hit that dramatically raises the tension and propels the story toward the "All is Lost" beat.`,
    example:
      "In 'Harry Potter and the Philosopher's Stone', this section includes Harry and his friends facing various challenges while trying to find the Philosopher's Stone before Snape does.",
    targetLength: "4 scenes or 4,000-8,000 words.",
  },
  {
    id: "11",
    name: "All Is Lost",
    order: 11,
    scenes: [21],
    description:
      "The darkest moment in the story, where everything the protagonist has been working towards falls apart.",
    detailedInstructions: `This beat should feel like a 'death' of sorts. It might not be a physical death, but the stakes are so high and the failure so devastating that it feels like a death. The protagonist's greatest fears have come to life, and their goals seem impossible to achieve.`,
    sceneInstructions: `The "All Is Lost" beat should consist of 1 scene:
    
    Scene 21. All Is Lost`,
    example:
      "In 'Star Wars: A New Hope', the All Is Lost moment is when Obi-Wan Kenobi is killed by Darth Vader.",
    targetLength: "One scene or 1,000-2,000 words.",
  },
  {
    id: "12",
    name: "Dark Night of the Soul",
    order: 12,
    scenes: [22, 23],
    description:
      "A moment of reflection and introspection for the protagonist. They must confront their own shortcomings and decide whether they will continue their journey.",
    detailedInstructions: `The protagonist has lost all hope. They question their path, their relationships, and even their own identity. But it's through this introspection that they find the strength or resolution to move forward. This beat often involves an emotional low point, a moment of insight, and a hint at the potential solution or change needed.`,
    sceneInstructions: `The "Dark Night of the Soul" beat should consist of 2 scenes:
      
    Scene 22. Fall from Grace: The protagonist suffers a severe setback. This could involve losing their team, failing to achieve a critical objective, or otherwise being stripped of their hope or resources. The audience sees the protagonist at their lowest point, emphasizing the seriousness of the situation and how much the protagonist stands to lose.

    Scene 23. Moment of Despair: Here, the protagonist is grappling with their failure. They may question their goals, abilities, or the feasibility of their mission. This is a deeply emotional moment that showcases the character's vulnerability.`,
    example:
      "In 'The Lion King', Simba's Dark Night of the Soul is when he sees a vision of his father in the sky, reminding him of who he truly is.",
    targetLength: "2 scenes or 2,000-4,000 words.",
  },
  {
    id: "13",
    name: "Break Into Three",
    order: 13,
    scenes: [24],
    description:
      "The protagonist, after introspection, finds the solution to their problems and decides to take action, marking the transition to Act Three.",
    detailedInstructions: `This beat involves a 'Eureka' moment where the protagonist finds new strength or a fresh perspective, allowing them to formulate a plan and take decisive action. It often comes as a result of the introspection during the 'Dark Night of the Soul', and it leads into the 'Finale' where the protagonist confronts their problem or antagonist head-on.`,
    sceneInstructions: `The "Break Into Three" beat should consist of 1 scene:
    
    Scene 24. Break Into Three`,
    example:
      "In 'The Matrix', the Break Into Three moment is when Neo decides to rescue Morpheus, believing he's the One and can manipulate the Matrix.",
    targetLength: "One scene or 1,000-2,000 words.",
  },
  {
    id: "14",
    name: "Finale",
    order: 14,
    scenes: [25, 26, 27, 28, 29],
    description:
      "The story reaches its climax as the protagonist confronts their problem or antagonist head-on.",
    detailedInstructions: `In the finale, the protagonist takes action, applying the lessons learned throughout their journey. This beat often involves a confrontation with the antagonist or a critical resolution of the main conflict. It's a time of high tension, drama, and excitement, where the protagonist's transformation is demonstrated through action. Additionally, it may involve wrapping up subplots and answering remaining questions.`,
    sceneInstructions: `The "Finale" beat should consist of 5 scenes: 
    
    Scene 25. Gathering the Team: The protagonist rallies their resources, getting ready for the final confrontation. This could involve gathering allies, tools, knowledge, or mustering up their inner strength.

    Scene 26. Executing the Plan: The protagonist and their team execute their plan to resolve the story's central conflict. This is typically where things appear to go well at first.
      
    Scene 27. The High Tower Surprise: An unexpected twist occurs that makes the situation look hopeless. The plan falls apart, and the protagonist is on their own, having to face the problem without the help they counted on.
      
    Scene 28. Dig, Deep Down: The protagonist has a moment of introspection, finds their inner strength, and remembers why they are fighting in the first place. They find the resolve to keep going despite the odds.
      
    Scene 29. Executing the New Plan: Empowered by their newfound strength or understanding, the protagonist confronts the conflict head-on and overcomes it, often in a way that reflects their personal growth over the course of the story. This beat brings the climax of the story and leads into the resolution or denouement.`,
    example:
      "In 'Harry Potter and the Philosopher's Stone', the Finale is when Harry confronts Professor Quirrell and Voldemort, protects the Philosopher's Stone, recovers in the hospital wing, and attends the end-of-year feast.",
    targetLength: "5 scenes or 5,000-10,000 words.",
  },
  {
    id: "15",
    name: "Final Image",
    order: 15,
    scenes: [30],
    description:
      "The closing moment of the story that provides resolution and mirrors the opening image, showing how the protagonist and their world have changed.",
    detailedInstructions: `This beat wraps up the story and provides a satisfying conclusion. It should mirror the opening image to show the contrast between where things started and where they ended. It often involves a resolution of the plot, theme, and character arcs, giving a sense of closure and leaving the reader with a final impression.`,
    sceneInstructions: `The "Final Image" beat should consist of 1 scene:
    
    Scene 30. Final Image`,
    example:
      "In 'Star Wars: A New Hope', the Final Image is the ceremony where Luke and Han are honored for their bravery, contrasting with Luke's humble life on Tatooine at the start.",
    targetLength: "One scene or 1,000-2,000 words.",
  },
];
