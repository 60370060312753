import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authState, selectedProject } from "../redux/authSlice";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase.config";
import { StoryType } from "../typings/StoryType";

export const useSelectStory = (
  storyId: string | undefined
): [StoryType | null, string | null] => {
  const state = useSelector(authState);
  const dispatch = useDispatch();
  const [story, setStory] = useState<StoryType | null>(null);
  const [prompt, setPrompt] = useState<string | null>(null);

  useEffect(() => {
    if (storyId && state.uid) {
      const docRef = doc(db, `users/${state.uid}/stories`, storyId);

      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setStory(docSnap.data() as StoryType);
          dispatch(
            selectedProject({ id: storyId, name: docSnap.data()?.name })
          );
        } else {
          console.log(`No such story!`);
          setStory(null);
        }
      });

      // Clean up subscription on unmount
      return () => unsubscribe();
    }
  }, [storyId, state.uid, dispatch]);

  useEffect(() => {
    if (story) {
      let prompt = `You are writing elements for the following story:\n\n`;
      if (story.name) prompt += "Title: " + story.name + "\n\n";
      if (story.summary) prompt += "Story summary: " + story.summary + "\n\n";
      if (story.synopsis) prompt += "Synopsis: " + story.synopsis + "\n\n";
      if (story.writingStyle) prompt += "Writing style: " + story.writingStyle;

      setPrompt(prompt);
    }
  }, [story]);

  return [story, prompt]; // return the selected story
};
