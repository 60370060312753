import {
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { auth, db } from "../firebase.config";
import { useNavigate } from "react-router-dom";

import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { login, logout, authState } from "../redux/authSlice";
import google from "../assets/btn_google_signin_dark_normal_web@2x.png";
import logo from "../assets/plotify_black_t.png";

export default function Login() {
  const state = useSelector(authState);
  const dispatch = useDispatch();
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");

  const actionCodeSettings = {
    url: window.location.origin + "/finish-login",
    handleCodeInApp: true,
  };

  const handleEmailSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus("");
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", email);
        setStatus("Click sign in link sent to your email");
        setError("");
      })
      .catch((error) => {
        console.log("ERROR", error);
        const err = error.message.split("Firebase: ");
        setError(err.length > 1 ? err[1] : "Problem signing in");
      });
  };

  function handleSignin() {
    setStatus("");
    signInWithPopup(auth, provider)
      .then((result) => {
        GoogleAuthProvider.credentialFromResult(result);

        dispatch(
          login({
            uid: result.user.uid,
            email: result.user.email,
            displayName: result.user.displayName,
          })
        );

        setDoc(
          doc(db, "users", result.user.uid),
          {
            uid: result.user.uid,
            displayName: result.user.displayName,
            email: result.user.email,
            photoURL: result.user.photoURL,
          },
          { merge: true }
        );

        navigate("/tools");
      })
      .catch((error) => {
        console.log("ERROR", error);
        setError(error.message);
      });
  }

  function handleSignout() {
    signOut(auth);
    dispatch(logout());
  }

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-md px-12 py-10 mx-auto my-auto bg-[#ffffff] space-y-5 rounded-md shadow-lg">
      <img className="object-contain w-full h-32" src={logo} alt="logo" />

      {!state.uid && (
        <p className="text-lg">Sign in to create a free account.</p>
      )}

      {state.uid ? (
        <button className="btn btn-primary" onClick={handleSignout}>
          Sign out
        </button>
      ) : (
        <>
          <img
            className="object-contain w-full px-6 mx-auto cursor-pointer"
            src={google}
            alt="google signin"
            onClick={handleSignin}
          />

          <form
            onSubmit={handleEmailSubmit}
            className="flex flex-col w-full px-6 mx-auto space-y-2"
          >
            <div className="text-center">Passwordless Sign in (Email Link)</div>
            <input
              className="px-3 py-2 text-black border rounded-md outline-none"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
            />

            {status.length > 0 ? (
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={!email}
              >
                <span className="material-symbols-rounded">mail</span>
                <span>Check Email</span>
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                disabled={!email}
              >
                <span className="material-symbols-rounded">lock</span>
                <span>Send Sign-in Link</span>
              </button>
            )}
          </form>
          {error ? (
            <div className="text-center text-orange-300">{error}</div>
          ) : status ? (
            <div className="text-center text-orange-300">{status}</div>
          ) : (
            <div className="opacity-0">nothing</div>
          )}
        </>
      )}
    </div>
  );
}
