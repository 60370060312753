import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { StoryType, defaultStory } from "../typings/StoryType";

const initialState: StoryType = defaultStory;

const storySlice = createSlice({
  name: "story",
  initialState,
  reducers: {
    createStory(state, action) {
      state = action.payload;
    },

    updateStory(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key as keyof StoryType] = action.payload[key];
      });
    },

    deleteStory(state) {
      state = {};
    },
  },
});

export const { createStory, updateStory, deleteStory } = storySlice.actions;

export const storyState = (state: RootState) => state.story;

export default storySlice.reducer;
