import { useSelector } from "react-redux";
import { StoryType, defaultStory } from "../typings/StoryType";
import { authState } from "../redux/authSlice";
import { FieldDefinitionType } from "../typings/FieldDefinitionType";
import { useSelectStory } from "../hooks/useSelectStory";
import DocumentForm from "../components/DocumentForm";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useCollection from "../hooks/useCollection";
import { CharacterType } from "../typings/CharacterType";

export default function Story() {
  const { storyId } = useParams<{ storyId?: string }>();
  const [story, prompt] = useSelectStory(storyId);
  const state = useSelector(authState);
  const [characters, , , , , , charactersSummary] =
    useCollection<CharacterType>(
      `users/${state.uid}/stories/${storyId}/characters`
    );

  const [fieldDefinitions, setFieldDefinitions] = useState<
    FieldDefinitionType[] | null
  >(null);

  console.log("Prompt: ", prompt);
  console.log("Characters: ", charactersSummary);

  useEffect(() => {
    if (prompt && story) {
      const relatedFieldList = [
        "logline",
        "concept",
        "hero",
        "theme",
        "setting",
        "pointOfView",
        "conflict",
        "resolution",
        "timeline",
        "targetAudience",
      ];
      setFieldDefinitions([
        { field: "name", name: "Name", type: "text", minRows: 1 },
        {
          field: "summary",
          name: "Summary",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt + `\n\nCharacters:\n` + charactersSummary,
          userPrompt: `Write a summary for the story sufficient to guide a subsequent OpenAI api completion prompt. Answer in 100 words or less with no heading.`,
          relatedFields: relatedFieldList,
        },
        {
          field: "synopsis",
          name: "Synopsis",
          type: "textarea",
          minRows: 6,
          systemPrompt: prompt + `\n\nCharacters:\n` + charactersSummary,
          userPrompt: `Write a detailed synopsis for the story. Answer with no heading.`,
          relatedFields: relatedFieldList,
        },
        {
          field: "logline",
          name: "Logline",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt + `\n\nCharacters:\n` + charactersSummary,
          userPrompt: `Write a logline for the story. Answer in 30 words or less with no heading.`,
          relatedFields: relatedFieldList,
        },

        {
          field: "concept",
          name: "Concept",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt + `\n\nCharacters:\n` + charactersSummary,
          userPrompt: `Based on the Larry Brooks "Story Engineering" model, write a concept for the story in 30 words or less starting with "What if".`,
          relatedFields: relatedFieldList,
        },
        {
          field: "hero",
          name: "Hero",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt + `\n\nCharacters:\n` + charactersSummary,
          userPrompt: `Based on the Larry Brooks "Story Engineering" model, describe the hero of the story in 50 words or less. Answer with no heading.`,
          relatedFields: relatedFieldList,
        },
        {
          field: "theme",
          name: "Theme",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt + `\n\nCharacters:\n` + charactersSummary,
          userPrompt: `Based on the Larry Brooks "Story Engineering" model, write a theme statement for the story in 30 words or less. Answer with no heading.`,
          relatedFields: relatedFieldList,
        },

        {
          field: "setting",
          name: "Setting",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt + `\n\nCharacters:\n` + charactersSummary,
          userPrompt: `Describe the setting for the story in 30 words or less. Answer with no heading.`,
          relatedFields: relatedFieldList,
        },

        {
          field: "timeline",
          name: "Timeline",
          type: "textarea",
          minRows: 3,
          systemPrompt: prompt + `\n\nCharacters:\n` + charactersSummary,
          userPrompt: `Describe the timeline of the story in 30 words or less. Answer with no heading.`,
          relatedFields: relatedFieldList,
        },

        {
          field: "pointOfView",
          name: "Point of View",
          type: "textarea",
          minRows: 1,
          systemPrompt: prompt + `\n\nCharacters:\n` + charactersSummary,
          userPrompt: `Describe the point of view the story in 20 words or less. Answer with no heading.`,
          relatedFields: relatedFieldList,
        },
        {
          field: "targetAudience",
          name: "Target Audience",
          type: "text",
          minRows: 1,
          systemPrompt: prompt + `\n\nCharacters:\n` + charactersSummary,
          userPrompt: `Describe the target audience of the story in 20 words or less. Answer with no heading.`,
          relatedFields: relatedFieldList,
        },
        {
          field: "writingStyle",
          name: "Writing Style",
          type: "text",
          minRows: 1,
        },
        { field: "genre", name: "Genre", type: "text", minRows: 1 },
        { field: "subgenre", name: "Subgenre", type: "text", minRows: 1 },
        { field: "notes", name: "Notes", type: "textarea", minRows: 3 },
        { field: "author", name: "Author", type: "text", minRows: 1 },
      ]);
    }
  }, [charactersSummary, prompt, story]);

  return (
    <div className="flex flex-col p-5 space-y-3 text-black bg-white">
      {state.uid && fieldDefinitions && storyId && (
        <DocumentForm<StoryType>
          initialValues={defaultStory}
          fieldDefinitions={fieldDefinitions}
          title={`Selected Story`}
          collectionName={`users/${state.uid}/stories`}
          docId={storyId}
        />
      )}
    </div>
  );
}
