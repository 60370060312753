import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { ClipLoader, PulseLoader } from "react-spinners";
import TextareaAutosize from "react-textarea-autosize";
import { LockClosedIcon } from "../icons/LockClosedIcon";
import { LockOpenIcon } from "../icons/LockOpenIcon";
import { ChevronUpIcon } from "../icons/ChevronUpIcon";
import { ChevronDownIcon } from "../icons/ChevronDownIcon";
import { authState, selectedModule } from "../redux/authSlice";
import { ApiMessageType } from "../typings/ApiMessageType";
import { processChat } from "../utils/processChat";
import generateDocx from "../utils/generateDocx";
import useCollection from "../hooks/useCollection";
import { DocumentIcon } from "../icons/DocumentIcon";
import { SelectIcon } from "../icons/SelectIcon";
import { EditIcon } from "../icons/EditIcon";
import { GenerateIcon } from "../icons/GenerateIcon";
import { DeleteIcon } from "../icons/DeleteIcon";
import { CancelIcon } from "../icons/CancelIcon";
import { SaveIcon } from "../icons/SaveIcon";
import { AddIcon } from "../icons/AddIcon";
import { CloseIcon } from "../icons/CloseIcon";

interface InputProps {
  id?: string;
  order?: number;
  name?: string;
  summary?: string;
  description?: string;
  content?: string;
  locked?: boolean;
}

type DocumentProps<T extends InputProps> = {
  title: string;
  collectionName: string;
  baseUrl: string;
  systemPrompt?: string;
  userPrompt?: string;
  generateMultipleItems?: () => Promise<T[]>;
  generateAutopilot?: () => Promise<void>;
  allowAdditions?: boolean;
  incomingSelectedId?: string | null;
};

export default function CollectionList<T extends InputProps>({
  title,
  collectionName,
  baseUrl,
  systemPrompt,
  userPrompt,
  generateMultipleItems,
  generateAutopilot,
  allowAdditions = true,
  incomingSelectedId,
}: DocumentProps<T>) {
  const navigate = useNavigate();
  const state = useSelector(authState);
  const dispatch = useDispatch();

  const [loadedItems, loading, add, update, remove, error, summary] =
    useCollection<T>(collectionName);

  const items: T[] = loadedItems
    .filter((item) => item.id)
    .sort((a, b) => (a?.order || 0) - (b?.order || 0));

  const [generatedItems, setGeneratedItems] = useState<T[]>([]);
  const [generating, setGenerating] = useState(false);
  const [generatingAutopilot, setGeneratingAutopilot] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(
    incomingSelectedId || null
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const emptyItem = { name: "", summary: "" };
  const [newItem, setNewItem] = useState<T>(emptyItem as T);
  const [isEditing, setIsEditing] = useState<string | null>(null);
  const [editedItem, setEditedItem] = useState<T | null>(null);

  const originalItem = items.find((item) => item.id === selectedId);

  const handleUp = (item: T, index: number) => {
    if (index === 0) return; // already at top or only item
    const neighbourItem = items[index - 1]; // item above the current item

    // Ensure item.id and neighbourItem.id are not undefined
    if (!item.id || !neighbourItem.id) return;

    const updatedItem = { ...item, order: neighbourItem.order };
    const updatedNeighbour = { ...neighbourItem, order: item.order };

    update(item.id, updatedItem);
    update(neighbourItem.id, updatedNeighbour);
  };

  const handleDown = (item: T, index: number) => {
    if (index === items.length - 1) return; // already at bottom
    const neighbourItem = items[index + 1]; // item below the current item

    // Ensure item.id and neighbourItem.id are not undefined
    if (!item.id || !neighbourItem.id) return;

    const updatedItem = { ...item, order: neighbourItem.order };
    const updatedNeighbour = { ...neighbourItem, order: item.order };

    update(item.id, updatedItem);
    update(neighbourItem.id, updatedNeighbour);
  };

  const handleEdit = (item: T) => {
    if (!item.id) return;
    setIsEditing(item.id);
    setEditedItem(item);
  };

  const handleSave = async (id: string) => {
    if (!state.uid) return;
    if (editedItem) {
      await update(id, editedItem);
      console.log("SAVED", editedItem);
      console.log("ERROR", error);
      setIsEditing(null);
      setEditedItem(null);
    }
  };

  const handleDelete = async (id: string) => {
    if (!state.uid) return;
    if (window.confirm("Are you sure you want to delete this?")) {
      await remove(id);
    }
  };

  const handleEditedInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (editedItem) {
      let value = e.target.value;

      if (e.target.name === "order") {
        let parsedValue = parseInt(e.target.value, 10);
        if (!isNaN(parsedValue)) {
          setEditedItem({
            ...editedItem,
            [e.target.name]: parsedValue,
          });
        } else {
          return;
        }
      } else {
        setEditedItem({
          ...editedItem,
          [e.target.name]: value,
        });
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(null);
    setEditedItem(null);
  };

  const toggleItem = (id: string) => {
    setSelectedId((prevId) => (prevId === id ? null : id));
    handleCancel();
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewItem({
      ...newItem,
      [e.target.name]: e.target.value,
    });
  };

  const addItem = async () => {
    if (!state.uid) return;
    newItem && (await add({ ...newItem, order: items.length + 1 }));
    setNewItem(emptyItem as T);
    closeModal();
  };

  const addGeneratedItem = async (generatedItem: T, index: number) => {
    if (!state.uid || !state.selectedProject) return;
    await add(generatedItem);

    // Remove the selected item from the list of generated items
    const newGeneratedItems = [...generatedItems];
    newGeneratedItems.splice(index, 1);
    setGeneratedItems(newGeneratedItems);
  };

  const generateItems = async () => {
    if (generateMultipleItems) {
      console.log("Generating content with generateMultipleItems()");
      setGenerating(true);
      try {
        const generatedItems = await generateMultipleItems();
        setGenerating(false);
        setGeneratedItems(generatedItems);
      } catch (error) {
        setGenerating(false);
        console.error("Error generating items: ", error);
      }
    } else {
      if (!systemPrompt || !userPrompt) return;

      console.log("Generating content: " + systemPrompt + ": " + userPrompt);

      setGenerating(true);

      try {
        let messages: ApiMessageType[] = [
          {
            role: "system",
            content: systemPrompt,
          },
          {
            role: "user",
            content: userPrompt,
          },
        ];

        console.log("MESSAGES", messages);

        const response = await processChat({ messages });
        console.log("RESPONSE", response);

        if (!response) throw new Error("No response");
        setGenerating(false);
        let dataObject = JSON.parse(response);
        setGeneratedItems(dataObject.map((item: T) => ({ ...item })));
      } catch (error) {
        setGenerating(false);
        console.error("Error generating items: ", error);
      }
    }
  };

  async function handleAutopilot() {
    if (!generateAutopilot) return;
    setGeneratingAutopilot(true);
    await generateAutopilot();
    setGeneratingAutopilot(false);
  }

  function handlePublish() {
    if (items.length === 0) return;
    const data = items.map((item) => ({
      title: item.name || "",
      section: `${item.order}`,
      content: item.content
        ? item.content
        : item.description
        ? item.description
        : item?.summary || "",
    }));

    let fileText: string = "Plotify";
    if (state.selectedProjectName) fileText += ` ${state.selectedProjectName}`;
    if (state.selectedModuleName) fileText += ` ${state.selectedModuleName}`;

    generateDocx(data, fileText);
  }

  return (
    <div className="flex flex-col flex-1 p-5 space-y-3 bg-white">
      <div className="text-2xl font-semibold">
        Project: {state.selectedProjectName}
        {state.selectedModuleName && ` / ${state.selectedModuleName}`}
      </div>
      <div className="flex items-center justify-between">
        <div className="text-2xl font-semibold">{title}</div>
        <div className="flex space-x-1">
          <button
            onClick={handlePublish}
            disabled={generating || generatingAutopilot}
            className="flex items-center justify-center w-10 h-10 text-white bg-gray-400 rounded-md hover:bg-blue-500 disabled:opacity-50 disabled:hover:bg-gray-500"
          >
            <DocumentIcon />
          </button>

          {allowAdditions && generateAutopilot && (
            <button
              onClick={handleAutopilot}
              disabled={generating || generatingAutopilot}
              className="p-2 text-white bg-gray-400 rounded-md hover:bg-blue-500 disabled:opacity-50 disabled:hover:bg-gray-400"
            >
              {generatingAutopilot ? (
                <PulseLoader color="white" size={8} />
              ) : (
                "Autopilot"
              )}
            </button>
          )}

          {allowAdditions &&
            ((systemPrompt && userPrompt) || generateMultipleItems) && (
              <button
                className="flex items-center justify-center w-10 h-10 text-white bg-gray-400 rounded-md hover:bg-blue-500 disabled:opacity-50 disabled:hover:bg-gray-400"
                onClick={generateItems}
                disabled={generating || generatingAutopilot}
              >
                {generating ? (
                  <ClipLoader color="white" size={18} />
                ) : (
                  <GenerateIcon />
                )}
              </button>
            )}
          {allowAdditions && (
            <button
              className={`p-2 text-white ${
                isModalOpen
                  ? "bg-gray-400 rounded-md hover:bg-red-500 disabled:opacity-50 disabled:hover:bg-gray-400"
                  : "bg-gray-400 rounded-md hover:bg-blue-500 disabled:opacity-50 disabled:hover:bg-gray-400"
              } `}
              onClick={isModalOpen ? closeModal : openModal}
              disabled={generating || generatingAutopilot}
            >
              {isModalOpen ? <CloseIcon /> : <AddIcon />}
            </button>
          )}
        </div>
      </div>
      {isModalOpen && (
        <div className="p-3 bg-green-200 rounded-md shadow-lg">
          <div className="flex flex-col space-y-2 modal-content">
            <div className="px-1">Create New Item</div>
            <input
              className="w-full px-2 py-1 border border-gray-300 rounded-md outline-none"
              type="text"
              name="name"
              placeholder="Name"
              value={newItem.name}
              onChange={handleInputChange}
            />

            <TextareaAutosize
              minRows={2}
              className="w-full px-2 py-1 border border-gray-300 rounded-md outline-none"
              name="summary"
              placeholder="Summary"
              value={newItem.summary}
              onChange={handleInputChange}
            />

            <div className="flex justify-end">
              <button
                className="flex items-center justify-center w-10 h-10 text-white bg-gray-400 rounded-md hover:bg-blue-500 disabled:opacity-50 disabled:hover:bg-gray-400"
                onClick={addItem}
                disabled={!newItem.name}
              >
                <SaveIcon />
              </button>
            </div>
          </div>
        </div>
      )}

      {items.map((item, index) => (
        <div
          key={item.id}
          className="flex flex-col p-3 my-2 bg-gray-100 rounded-md shadow-md cursor-pointer"
          onClick={() => item.id && toggleItem(item.id)}
        >
          <div className="flex items-center justify-between">
            <div className="flex w-full">
              <div className="flex">
                <div className="flex flex-col h-full">
                  <button
                    disabled={index === 0}
                    onClick={(e) => {
                      e.stopPropagation();
                      item.id && handleUp(item, index);
                    }}
                    className="flex-1 opacity-40 hover:opacity-100 disabled:hover:opacity-40"
                  >
                    <ChevronUpIcon />
                  </button>
                  <button
                    disabled={index === items.length - 1}
                    onClick={(e) => {
                      e.stopPropagation();
                      item.id && handleDown(item, index);
                    }}
                    className="flex-1 opacity-40 hover:opacity-100 disabled:hover:opacity-40"
                  >
                    <ChevronDownIcon />
                  </button>
                </div>
                <input
                  className="w-10 px-2 py-1 text-lg font-semibold text-center border rounded-md outline-none disabled:cursor-pointer"
                  type="text"
                  name="order"
                  value={
                    isEditing === item.id
                      ? editedItem?.order?.toString() || ""
                      : item.order?.toString() || ""
                  }
                  onClick={(e) => e.stopPropagation()}
                  onChange={handleEditedInputChange}
                  disabled={isEditing !== item.id || item.locked}
                />
              </div>
              <input
                className="w-full px-2 py-1 text-lg font-semibold outline-none disabled:cursor-pointer"
                type="text"
                name="name"
                value={isEditing === item.id ? editedItem?.name : item.name}
                onClick={(e) => e.stopPropagation()}
                onChange={handleEditedInputChange}
                disabled={isEditing !== item.id || item.locked}
              />
            </div>
            <div className="flex items-center ml-2 space-x-2">
              {isEditing === item.id ? (
                <>
                  <button
                    className="flex items-center justify-center w-10 h-10 text-white bg-green-500 rounded-md hover:bg-green-700 disabled:opacity-50 disabled:hover:bg-green-500"
                    disabled={isEqual(originalItem, editedItem)}
                    onClick={(e) => {
                      e.stopPropagation();
                      item.id && handleSave(item.id);
                    }}
                  >
                    <SaveIcon />
                  </button>
                  <button
                    className="flex items-center justify-center w-10 h-10 text-white bg-red-500 rounded-md hover:bg-red-700"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCancel();
                    }}
                  >
                    <CancelIcon />
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="flex items-center justify-center w-10 h-10 text-white bg-gray-400 rounded-md hover:bg-blue-500 disabled:opacity-50 disabled:hover:bg-gray-400"
                    onClick={(e) => {
                      e.stopPropagation();

                      item && handleEdit(item);
                    }}
                    disabled={item.locked}
                  >
                    <EditIcon />
                  </button>

                  {allowAdditions && (
                    <button
                      className="flex items-center justify-center w-10 h-10 text-white bg-gray-400 rounded-md hover:bg-red-500 disabled:opacity-50 disabled:hover:bg-gray-400"
                      onClick={(e) => {
                        e.stopPropagation();
                        item.id && handleDelete(item.id);
                      }}
                      disabled={item.locked}
                    >
                      <DeleteIcon />
                    </button>
                  )}
                </>
              )}

              <button
                className={`flex items-center justify-center w-10 h-10 text-white rounded-md ${
                  state.selectedProject === item.id ||
                  state.selectedModule === item.id
                    ? "bg-green-500 hover:bg-green-700"
                    : "bg-gray-400 hover:bg-blue-500"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (state.selectedModule !== item.id && title === "Modules") {
                    dispatch(
                      selectedModule({
                        id: item.id,
                        name: item.name,
                      })
                    );
                  }
                  navigate(baseUrl + item.id);
                }}
              >
                <SelectIcon />
              </button>
              <button
                disabled
                className="flex items-center justify-center w-10 h-10 disabled:cursor-not-allowed disabled:opacity-30"
              >
                {item?.locked ? <LockClosedIcon /> : <LockOpenIcon />}
              </button>
            </div>
          </div>

          <div className={`flex flex-col space-y-2 text-sm text-gray-700 `}>
            <div>
              <TextareaAutosize
                minRows={2}
                className="w-full px-2 py-1 text-base outline-none disabled:cursor-pointer"
                name="summary"
                value={
                  isEditing === item.id ? editedItem?.summary : item.summary
                }
                onClick={(e) => e.stopPropagation()}
                onChange={handleEditedInputChange}
                disabled={isEditing !== item.id || item.locked}
              />
            </div>
          </div>
        </div>
      ))}

      {generatedItems && generatedItems.length > 0 && (
        <div>
          <div className="text-2xl font-semibold">Generated Items</div>
          <div className="flex flex-col space-y-2">
            {generatedItems.length >= 0 &&
              generatedItems.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col p-3 space-y-2 border rounded-md shadow-md cursor-pointer hover:bg-slate-200 bg-slate-50"
                  onClick={() => addGeneratedItem(item, index)}
                >
                  <div>Name: {item.name}</div>
                  <div>Order: {item.order}</div>
                  <div>Summary: {item.summary}</div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
