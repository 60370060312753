import { useEffect, useState } from "react";
import { db } from "../firebase.config";
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
  Timestamp,
  setDoc,
} from "firebase/firestore";

interface RecordData {
  id?: string;
  createdSeconds?: number;
  updatedSeconds?: number;
}

const useCollection = <T extends RecordData>(
  path: string
): [
  T[],
  boolean,
  (data: T) => Promise<void>,
  (id: string, data: Partial<T>) => Promise<void>,
  (id: string) => Promise<void>,
  string | null,
  string | null
] => {
  const [items, setItems] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [summary, setSummary] = useState<string | null>(null);

  useEffect(() => {
    if (!path) {
      setLoading(false);
      return;
    }

    const collectionRef = collection(db, path);
    const unsubscribe = onSnapshot(
      collectionRef,
      (snapshot) => {
        const docs = snapshot.docs.map(
          (doc) => ({ id: doc.id, ...doc.data() } as unknown as T)
        );
        setItems(docs);
        setLoading(false);
      },
      (error) => {
        setError(error.message || "Error fetching documents");
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [path]);

  const add = async (data: Partial<T>) => {
    setError(null);
    if (!path) {
      setError("Invalid collection path");
      return;
    }
    try {
      const timestamp = Timestamp.now();
      const docRef = doc(collection(db, path));
      const id = docRef.id;

      await setDoc(docRef, {
        ...data,
        id,
        createdSeconds: timestamp.seconds,
        updatedSeconds: timestamp.seconds,
      });

      return null;
    } catch (error: any) {
      setError(error.message || "Error adding document");
      return error.message;
    }
  };

  const update = async (id: string, data: Partial<T>) => {
    setError(null);
    if (!path) {
      setError("Invalid collection path");
      return;
    }
    try {
      const docRef = doc(db, `${path}/${id}`);

      await updateDoc(docRef, {
        ...data,
        updatedSeconds: Timestamp.now().seconds,
      });

      return null;
    } catch (error: any) {
      setError(error.message || "Error updating document");
      return error.message;
    }
  };

  const remove = async (id: string) => {
    setError(null);
    if (!path) {
      setError("Invalid collection path");
      return;
    }
    try {
      const docRef = doc(db, `${path}/${id}`);
      await deleteDoc(docRef);
      return null;
    } catch (error: any) {
      setError(error.message || "Error deleting document");
      return error.message;
    }
  };

  useEffect(() => {
    if (items.length > 0 && !loading) {
      const summary = items
        .sort(
          (a, b) =>
            (a["order" as keyof T] as number) -
            (b["order" as keyof T] as number)
        )
        ?.map((item) => {
          if (item["name" as keyof T]?.toString()) {
            return `${item["order" as keyof T]}. ${item[
              "name" as keyof T
            ]?.toString()}: ${item["summary" as keyof T]?.toString()}\n`;
          } else {
            return "";
          }
        })
        .join("");
      setSummary(summary);
    }
  }, [items, loading]);

  return [items, loading, add, update, remove, error, summary];
};

export default useCollection;
