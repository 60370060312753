export type StoryType = {
  //Editable Properties
  name?: string; // The title or name of the story.
  author?: string; // The author or authors of the story.
  logline?: string; // A brief summary of the story, often used in marketing.
  summary?: string; // Summary of the story designed for use in an AI prompt.
  synopsis?: string; // A more detailed summary of the story.

  concept?: string; // The main concept or idea behind the story.
  hero?: string; // The main character of the story.
  theme?: string; // The underlying theme or message of the story.

  setting?: string; // The primary location(s) or time period(s) in which the story takes place.
  genre?: string; // The main genre of the story (e.g., fantasy, science fiction, romance).
  subgenre?: string; // Any sub-genres of the story.

  pointOfView?: string; // The narrative point of view used in the story (e.g., first person, third person limited).
  conflict?: string; // The main conflict or problem that drives the plot.
  resolution?: string; // How the main conflict is resolved.
  timeline?: string; // The sequence of events or the timeframe of the story.
  targetAudience?: string; // The intended audience for the story.

  notes?: string; // Any additional notes or reminders about the story.
  beatsheetSummary?: string; // Summary of all the beats designed for use in an AI prompt.
  charactersSummary?: string; // Summary of all the characters designed for use in an AI prompt.
  writingStyle?: string; // The writing style of the story (e.g., formal, informal, academic).

  //Read-Only Properties
  order?: number; // The position of the story in a series or collection.
  wordCount?: number; // The total number of words in the story.
  createdSeconds?: number; // The timestamp for when the story was created.
  updatedSeconds?: number; // The timestamp for when the story was last updated.
  locked?: boolean; // Whether the story is locked for editing.

  // ID Properties
  id?: string; // The unique identifier for the story.
};

export const defaultStory: StoryType = {
  name: "", // The title or name of the story.
  summary: "", // Summary of the story designed for use in an AI prompt.
  author: "", // The author or authors of the story.
  logline: "", // A brief summary of the story, often used in marketing.
  synopsis: "", // A more detailed summary of the story.

  concept: "", // The main concept or idea behind the story.
  hero: "", // The main character of the story.
  theme: "", // The underlying theme or message of the story.

  setting: "", // The primary location(s) or time period(s) in which the story takes place.
  genre: "", // The main genre of the story (e.g., fantasy, science fiction, romance).
  subgenre: "", // Any sub-genres of the story.
  pointOfView: "", // The narrative point of view used in the story (e.g., first person, third person limited).
  conflict: "", // The main conflict or problem that drives the plot.
  resolution: "", // How the main conflict is resolved.
  timeline: "", // The sequence of events or the timeframe of the story.
  targetAudience: "", // The intended audience for the story.

  notes: "", // Any additional notes or reminders about the story.
  beatsheetSummary: "", // Summary of all the beats designed for use in an AI prompt.
  charactersSummary: "", // Summary of all the characters designed for use in an AI prompt.
  writingStyle: "", // The writing style of the story (e.g., formal, informal, academic).

  order: 0, // The position of the story in a series or collection.
  wordCount: 0, // The total number of words in the story.
  createdSeconds: 0, // The timestamp for when the story was created.
  updatedSeconds: 0, // The timestamp for when the story was last updated.
  locked: false, // Whether the story is locked for editing.
  id: "", // The unique identifier for the story.
};
